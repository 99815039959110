<template>
    <v-row>
      <v-col cols="12" md="3">
        <AdminMenu></AdminMenu>
      </v-col>
      <v-col cols="12" md="9" class="adminBg">
        <v-layout wrap class="mt-3">
          <v-col cols="12">
            <h4>Wallet Module</h4>
          </v-col>
        </v-layout>
        <v-layout wrap class="mt-5">
          
          <v-col cols="12" md="4">
            <v-layout class="boxStat" style="border: 1px solid #72BD61;">
              <v-col cols="12">
                <v-layout>
                  <v-col cols="12">
                    <span class="ml-5">Total Transactions</span>
                  </v-col>
                </v-layout>
                <v-layout>
                  <v-col cols="6">
                    <span class="text-h5 ml-5"><b>{{totalTransactions}}</b></span>
                  </v-col>
                  <v-col cols="3" align="right">
                    <!-- <v-btn small>
                      <v-icon size="20">mdi-chevron-right</v-icon>
                    </v-btn> -->
                  </v-col>
                </v-layout>
              </v-col>
            </v-layout>
          </v-col>
  
          <v-col cols="12" md="4">
            <v-layout class="boxStat" style="border: 1px solid #2776ED;">
              <v-col cols="12">
                <v-layout>
                  <v-col cols="12">
                    <span class="ml-5">Pending Withdrawals</span>
                  </v-col>
                </v-layout>
                <v-layout>
                  <v-col cols="6">
                    <span class="text-h5 ml-5"><b>{{pendingTransactions.length}}</b></span>
                  </v-col>
                  <v-col cols="3" align="right">
                    <!-- <v-btn small>
                      <v-icon size="20">mdi-chevron-right</v-icon>
                    </v-btn> -->
                  </v-col>
                </v-layout>
              </v-col>
            </v-layout>
          </v-col>
  
          <v-col cols="12" md="4">
            <v-layout class="boxStat" style="border: 1px solid #61BDBD;">
              <v-col cols="12">
                <v-layout>
                  <v-col cols="12">
                    <span class="ml-5">Approved Withdrawal</span>
                  </v-col>
                </v-layout>
                <v-layout>
                  <v-col cols="6">
                    <span class="text-h5 ml-5"><b>{{approvedWithdrawals.length}}</b></span>
                  </v-col>
                  <v-col cols="3" align="right">
                    <!-- <v-btn small>
                      <v-icon size="20">mdi-chevron-right</v-icon>
                    </v-btn> -->
                  </v-col>
                </v-layout>
              </v-col>
            </v-layout>
          </v-col>
        </v-layout>

        <v-row wrap class="mt-2">
            <v-col cols="12">
                <v-progress-linear
                    color="#7CBF46"
                    v-show="loader"
                    :indeterminate="true"
                ></v-progress-linear>
            </v-col>
        </v-row>

        <v-row wrap class="mt-2">
            <v-col cols="12" v-if="transactionHistories.length >= 1">
              <v-layout
                class="boxStat mt-2"
                v-for="(transaction, key) in transactionHistories"
                :key="key"
              >
                <v-col cols="2" class="hidden-sm-and-down">
                  <v-icon size="30" v-if="transaction.status == 'approved'"  color="#72BD61">mdi-history</v-icon>
                <v-icon size="30" v-else style="color:crimson;">mdi-history</v-icon>
                </v-col>
                <v-col cols="8" md="6" align="left">
                  <h6>{{ transaction.description }}</h6>
                  <p>{{ transaction.createdAt | moment("dddd, MMMM Do YYYY, h:mm:ss a") }}</p>
                  <p>
                    <v-btn v-if="transaction.status == 'pending'" small color="#456928" dark @click="approvePayment(transaction.walletHistoryId)">Approve</v-btn>
                  </p>
                  
                </v-col>
                <v-col cols="4" align="right">
                  <p class="titleStyleColorSm">
                    {{ systemSetting.systemCurrency }}{{ transaction.amount | numberFormat }}
                  </p>
                  <span v-if="transaction.status == 'approved'" style="color:#456928;">
                    Payment Made
                  </span>
                  <span v-if="transaction.status == 'pending'" style="color:crimson;">
                    {{ transaction.status| ucwords }}
                  </span>
                  
                </v-col>
              </v-layout>
            </v-col>
          </v-row>
      </v-col>
    </v-row>
  </template>
  <script>
  import AdminMenu from "../others/AdminMenu";
  export default {
    name: "AdminProductsModule",
    components: {
      AdminMenu,
    },
    data() {
      return {
        systemSetting: {
          systemCurrency: "₦",
        },
        loader: false,
        transactionHistories: [],
        totalTransactions:[],
        pendingTransactions:[],
        approvedWithdrawals:[],
      };
    },
    created() {
      this.fetchWalletHistory();
    },
    methods: {
        fetchWalletHistory() {
            this.$http
            .get(`${process.env.VUE_APP_URL}withdrawals/`)
            .then((response) => {
                this.transactionHistories = response.data.data.rows.reverse();
                this.totalTransactions = response.data.data.count;
                response.data.data.rows.forEach(element => {
                if(element.status == 'pending'){
                    this.pendingTransactions.push(element);
                }
                if(element.status == 'approved'){
                    this.approvedWithdrawals.push(element);
                }
            });
            })
            .catch((error) => {
                this.errorMessage = error.response.data.data;
            });
        },
        approvePayment(walletHistoryId) {
            this.loader = true;
            this.$http
                .post(`${process.env.VUE_APP_URL}wallet/approve-transaction/` + walletHistoryId)
                .then((response) => {
                if (response.data.success == true) {
                    this.reloadPage();
                    this.loader = false;
                }
            })
            .catch((error) => {
            this.errorMessage = error.response.data.data;
            });
        },

        reloadPage(){
            location.reload();
        }
    },
    filters: {
        ucwords(value) {
        if (!value) return '';
        value = value.toString();
        return value.replace(/\b\w/g, function (l) {
            return l.toUpperCase();
        });
        },
        numberFormat(value) {
        if (!value) return '0';
        return new Intl.NumberFormat().format(value);
        }
    }
  };
  </script>
  <style scoped>
  .adminBg {
    background: #e5e5e5;
  }
  
  .whiteBg {
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px;
  }
  
  .boxStat {
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px;
  }
  </style>
  